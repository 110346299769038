<template>
  <div class="wrapper order-result">
    <div class="white-box" v-if="isOrderFailed">
      <!--h1>
        {{ $t('order-failed') }}
      </h1-->
      <h1>
        {{ simplePayDescription[0] }}
      </h1>
      <h3>
        {{ simplePayDescription[1].split(':')[0] }}
      </h3>
      <h1>
        {{ simplePayDescription[1].split(':')[1] }}
      </h1>
      {{ simplePayDescription[2] }}
    </div>
    <div class="white-box" v-else-if="isOrderPayed">
      <h1>
        {{ $t('order-result-order-number') }}: <span v-id="order">{{ order.serial }} </span>
      </h1>
      <h3>{{ $t('order-result-description') }}</h3>

      <div class="buttons" v-if="isTrackingBtnVisible">
        <button class="yellowBorderedBtn" @click="trackOrders()">
          {{ $t('order-result-track-orders') }}
        </button>
      </div>
    </div>
    <div class="white-box" v-else>
      <h3>{{ $t('order-pending') }}</h3>
    </div>
  </div>
</template>
<script>
import { getOrderById } from '../functions/api/payment';

export default {
  data() {
    return {
      orderId: '',
      mvmId: '',
      order: {},
    };
  },
  created() {
    window.localStorage.setItem('locale', this.$route.params.lang);
    this.$root.$i18n.locale = window.localStorage.getItem('locale');

    this.$store.commit('setSkyboxTitle', this.$t('title-order-success'));
    this.$store.commit('setSkyboxText', '');
    this.$store.commit('setSkyboxLink', '');
    this.$store.commit('setSkyboxLinkText', '');
    this.$store.commit('setMvmId', this.$route.params.mvmId);

    this.orderId = this.$route.params.orderId;
    this.mvmId = this.$route.params.mvmId;

    this.getOrderData();
  },
  computed: {
    isTrackingBtnVisible() {
      return this.order.buffet.type == 'simple';
    },
    isOrderFailed() {
      return this.order.status === 'orderFailed';
    },
    isOrderPayed() {
      return this.order.status === 'orderPayed';
    },
    simplePayDescription() {
      return this.order.simplePayDescription.split('</p><p>').map((str) => str.replaceAll('<p>', ''));
    },
  },
  methods: {
    home() {
      this.$router.push('/events');
    },
    trackOrders() {
      this.$router.push('/track-orders');
    },
    getOrderData() {
      getOrderById(this.mvmId, this.orderId).then((result) => {
        console.log(JSON.stringify(result));
        if (result.status != 'orderPayed' && result.status != 'orderFailed') {
          setTimeout(this.getOrderData, 1000);
        }
        this.order = result;
      });
    },
  },
};
</script>
